.bossDiv {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jugad {
  position: absolute;
  left: 148px;
  top: 80px;
}

.carousel {
  height: 550px;
  width: 70%;
  background-color: darkcyan;

  background-position: center;
  background-repeat: no;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 12fr 1fr;


  opacity: 0.8;
  transition-duration: 1s ease;
}


.carousel .left {
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  display: grid;

  place-items: center;
  cursor: pointer;
  color: white;
}



.carousel .center {
  height: 100%;
}

.carousel .right {
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  cursor: pointer;
  color: white;
}

.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
